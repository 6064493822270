import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import { showToast } from '@/helpers/toast.helper';
import { ProductCard } from '@/store/modules/products/actions';
import { ProductType } from '@/types/api/products/product.type';
import DragDropFileInput from '@/components/ui/inputs/drag-drop-file-input/drag-drop-file-input.vue';
import { BImg } from 'bootstrap-vue';

@Component({
  name: 'UploadProductImageModal',
  components: {
    ValidationObserver,
    DragDropFileInput,
    BImg,
  },
})
export default class UploadProductImageModal extends Vue {
  tryingToUpdate = false;
  file: null | File = null;

  @Prop({
    required: true
  })
  updateCallback!: (response?: any) => any;

  @Prop({
    required: true
  })
  product!: ProductType;

  @Action('productUploadImage', { namespace: 'products' })
  productUploadImage!: (data: ProductCard<File>) => Promise<void>;

  @Action('productDeleteImage', { namespace: 'products' })
  productDeleteImage!: (id: number) => Promise<void>;

  cancel() {
    this.$emit('close');
  }

  onFileSelect(file) {
    this.file = file;
  }

  get acceptFileType() {
    return [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/svg+xml',
    ];
  }

  onApply() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      this.productUploadImage({
        id: this.product.id,
        params: this.file as File
      })
        .then(response => {
          this.tryingToUpdate = false;
          this.updateCallback(response);
          this.cancel();
          showToast(this.$t('common.successUpdate') as any);
        })
        .catch(() => {
          this.tryingToUpdate = false;
          showToast(this.$t('common.errorUpdate') as any);
        });
    }
  }

  onClickRemove() {
    if (!this.tryingToUpdate) {
      this.tryingToUpdate = true;
      this.productDeleteImage(this.product.id)
        .then(response => {
          this.tryingToUpdate = false;
          this.updateCallback(response);
          this.cancel();
          showToast(this.$t('common.successDelete') as any);
        })
        .catch(() => {
          this.tryingToUpdate = false;
          showToast(this.$t('common.errorDelete') as any);
        });
    }
  }
}
