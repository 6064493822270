import { Component, Mixins } from 'vue-property-decorator';
import Layout from '@/components/layouts/main.vue';
import PageTitle from '@/components/ui/page-title/pageTitle.vue';
import { Action, Getter } from 'vuex-class';
import FilterMixin from '@/mixins/filter.mixin';
import GridTable from '@/components/ui/grid-table/gridTable.vue';
import { GridTableApi } from '@/components/ui/grid-table/gridTable';
import { MODAL_CONFIG } from '@/helpers/app.helper';
import { ProductsGetRequest } from '@/types/request/products-request.types';
import { ProductType } from '@/types/api/products/product.type';
import UpdateProductModal from '@/components/features/modals/update-product-modal/update-product-modal.vue';
import { ActionsCellRendererParams } from '@/components/ui/grid-table/cell/actions-cell-renderer/actionsCellRenderer';
import ActionsCellRenderer from '@/components/ui/grid-table/cell/actions-cell-renderer/actionsCellRenderer.vue';
import BoxHeader from '@/components/ui/box-header/boxHeader.vue';
import ImageCellRenderer from '@/components/ui/grid-table/cell/image-cell-renderer/image-cell-renderer.vue';
import UploadProductImageModal
  from '@/components/features/modals/upload-product-image-modal/upload-product-image-modal.vue';

const title = 'products.title';

@Component({
  name: 'Products',
  page: {
    title
  },
  components: {
    Layout,
    PageTitle,
    GridTable,
    ActionsCellRenderer,
    BoxHeader,
    ImageCellRenderer,
  },
})
export default class Products extends Mixins(FilterMixin) {
  title = title;
  table: GridTableApi | null = null;
  filter: any = this.initFilter();

  @Action('getProducts', { namespace: 'products' })
  getProducts!: (params?: ProductsGetRequest) => Promise<ProductType[]>;

  @Getter('getProducts', { namespace: 'products' })
  products!: ProductType[];

  created() {
    this.getProductsRequest();
  }

  getProductsRequest() {
    return this.getProducts(this.filter);
  }

  initFilter() {
    return {
      query: this.$route.query.query || null,
    };
  }

  get tableFields() {
    return [
      {
        label: this.$t('products.image'),
        key: 'imageUrl',
      },
      {
        label: this.$t('products.asin'),
        key: 'asin',
      },
      {
        label: this.$t('products.displayName'),
        key: 'displayName',
      },
      {
        label: this.$t('products.name'),
        key: 'name',
        thStyle: { width: '600px' },
        tdClass: ['no-wrap-disable'],
      },
      {
        label: '',
        key: 'actions',
        thStyle: { width: '40px' },
      },
    ];
  }

  getActionsParams(data): ActionsCellRendererParams<ProductType> {
    return {
      data: data.item,
      buttons: [
        {
          type: 'edit',
          actionCallback: this.onEditAction,
          btnContent: '<i class="fe-edit" />',
        },
        // {
        //   type: 'image',
        //   actionCallback: this.onImageUploadAction,
        //   btnContent: '<i class="fe-image" />',
        // },
      ]
    };
  }

  onEditAction(params: ActionsCellRendererParams<ProductType>) {
    this.$modal.show(UpdateProductModal, {
      product: params.data,
      updateCallback: () => {
        this.getProductsRequest();
      }
    }, MODAL_CONFIG);
  }

  onImageUploadAction(params: ActionsCellRendererParams<ProductType>) {
    this.$modal.show(UploadProductImageModal, {
      product: params.data,
      updateCallback: () => {
        this.getProductsRequest();
      }
    }, MODAL_CONFIG);
  }

  getImageParams(item: ProductType) {
    return {
      url: item.imageUrl
    }
  }
}
